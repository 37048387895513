import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { MARKS, BLOCKS } from '@contentful/rich-text-types'
import SEO from '../components/seo'
import { Layout } from '../components/Layout'
import { theme } from '../components/Layout/theme'
import { PageHeader } from '../components/Header/PageHeader'
import { H1, H3 } from '../components/Title'
import { Container, Column, Row } from '../components/Grid'
import { Paragraph, Bold, Italic } from '../components/Text'
import styled from '@emotion/styled'
import Image from 'gatsby-image'
import { Link } from 'gatsby'

const LivingLinkWrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  flex-wrap: wrap;
  @media (max-width: 580px) {
    flex-direction: column;
  }
`

const Badge = styled.span`
  background-color: ${({ theme }) => theme.darkPink};
  padding: 4px 8px 2px;
  position: absolute;
  z-index: 1;
  top: 0;
  transform: translateY(-50%);
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  font-family: BloggerSans-Medium;
  font-size: 12px;
  letter-spacing: 0;
`

const LivingPage = props => {
  const data = useStaticQuery(graphql`
    {
      pageContent: contentfulSection(slug: { eq: "workshops" }) {
        content {
          json
        }
      }

      background: file(relativePath: { eq: "workshops.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      engelenCirkelLogo: file(relativePath: { eq: "engelen-cirkel.jpg" }) {
        childImageSharp {
          fixed(quality: 100, width: 400) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      jaarVoorJezelfLogo: file(relativePath: { eq: "jaar-voor-jezelf.jpg" }) {
        childImageSharp {
          fixed(quality: 100, width: 400) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      angelExperienceLogo: file(relativePath: { eq: "angel-exp.jpg" }) {
        childImageSharp {
          fixed(quality: 100, width: 400) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      gratitudeJournal: file(relativePath: { eq: "gratitude-journal.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 400) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const richTextOptions = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <Paragraph>{children}</Paragraph>
      ),
      [BLOCKS.UL_LIST]: (_node, children) => (
        <ul
          style={{
            listStyle: 'unset',
            paddingLeft: '1rem',
          }}>
          {children}
        </ul>
      ),
      [BLOCKS.LIST_ITEM]: (_node, children) => <li>{children}</li>,
    },
  }

  const pageContent = documentToReactComponents(
    data.pageContent.content.json,
    richTextOptions,
  )

  return (
    <Layout>
      <SEO title="Living" />
      <PageHeader background={data.background.childImageSharp.fluid}>
        <H1
          textAlign="center"
          color={theme.darkPink}
          style={{ marginBottom: '11px' }}>
          Living
        </H1>
      </PageHeader>

      <Container>
        <Row style={{ padding: '60px 0 40px' }}>
          <Column display={{ md: 10, xs: 10 }} offset={{ md: 1, xs: 1 }}>
            {pageContent}
          </Column>
        </Row>
      </Container>
      <div
        style={{
          backgroundColor: '#FBF4F4',
        }}>
        <Container>
          <Row>
            <Column
              display={{ md: 10, xs: 12 }}
              offset={{ md: 1, xs: 0 }}
              style={{
                paddingTop: '60px',
                paddingBottom: '45px',
              }}>
              <LivingLinkWrapper>
                <Link
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  to="/living/engelen-cirkel">
                  <Image
                    fixed={data.engelenCirkelLogo.childImageSharp.fixed}
                    style={{ width: 200, height: 283, objectFit: 'cover' }}
                  />
                  <H3>Engelencirkel</H3>
                </Link>
                <Link
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  to="/living/een-jaar-voor-jezelf">
                  <Image
                    fixed={data.jaarVoorJezelfLogo.childImageSharp.fixed}
                    style={{ width: 200, height: 283, objectFit: 'cover' }}
                  />
                  <H3>Een jaar voor jezelf</H3>
                </Link>
                <Link
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  to="/living/the-angel-experience">
                  <Badge>Gratis!</Badge>
                  <Image
                    fixed={data.angelExperienceLogo.childImageSharp.fixed}
                    style={{ width: 200, height: 283, objectFit: 'cover' }}
                  />
                  <H3>The Angel Experience</H3>
                </Link>
                <a
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  href="/gratitude-journal.pdf"
                  download>
                  <Badge>Gratis!</Badge>
                  <Image
                    fixed={data.gratitudeJournal.childImageSharp.fixed}
                    style={{
                      width: 200,
                      height: 283,
                      objectFit: 'cover',
                      border: '1px solid #eee',
                    }}
                  />
                  <H3>Gratitude Journal</H3>
                </a>
              </LivingLinkWrapper>
            </Column>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default LivingPage
